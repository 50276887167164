import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
	Row,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Image,
	Link,
	Footer
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'
import spendPieChart from '../../../assets/images/spendPieChart.png'
import giftImpact from '../../../assets/images/giftImpact.png'


import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {
	constructor (props) {
		super(props)
	}

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.
							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li>Chapter 3: 4 Methods of Transparency that Millennials Love</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter3">Chapter 3: 4 Methods of Transparency that Millennials Love  </Subtitle>
					Millennials are very willing to give, but they are also very specific about where they give. They don’t simply want to throw their money into the dark abyss of “charity” but want to know that their funds are actually contributing to your cause--not your office water cooler or your CEO’s salary (even though you need to fund that too). Blackbaud’s Next Generation of American Giving Study found that nearly 60% of millennials cited the ability to see the direct impact of their donation as being critical in their decision process.
							<br/><br/>
					Use these 4 methods to increase your transparency:
							<ul>
								<li><strong>Create a way for donors to fund specific projects</strong>. Donors--especially millennials--are more likely to donate when they can see the specific goal or project they are funding. <Link style={{ display: 'inline' }} href="https://www.kiva.org">Kiva,</Link> a nonprofit that provides individuals in developing countries with micro-loans, has this method down to a tee. Donors are able to choose a category, browse through and learn about individuals and their needs, and finally select who they would like to fund.<Link style={{ display: 'inline' }} href="https://www.ijm.org/"> International Justice Mission,</Link> on the other hand, breaks down donation sizes and explains what each increment would accomplish (see photo). </li>
								<Image src={ giftImpact} />
								<li><strong>Earn a <Link style={{ display: 'inline' }} href="https://learn.guidestar.org/seals">GuideStar Seal of Transparency</Link> to display on your website.</strong> It takes less than 15 minutes and reassures potential donors that you are transparent and honest about where your money is going. </li>
								<li><strong>Post an annual report</strong> that outlines your fundraising and spending within the past year--and do it in an aesthetic and easy-to-read way. Check out this pie chart the <Link style={{ display: 'inline' }} href="https://www.dressember.org/"> Dressember Foundation </Link>featured in their annual report that breaks down where their money went: </li>
								<Row>
									<Image src={ spendPieChart } height={'400px'} width={'auto'} style={{ margin: '0 auto' }}/>
								</Row>
								<li><strong>Tell stories. </strong>Millennials <i>love </i>stories. Why do you think the top brands have shifted to story-based marketing? Stories appeal to emotion and are one of the most effective ways to show your donors the impact their contributions are making. Highlight the impact your organization has with a story of a specific individual or a community. Videos are generally the best way to do this but you also can’t go wrong with a well-written blog post.</li>
							</ul>

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>


							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
